import React, {useState, useRef} from 'react'
import { graphql } from 'gatsby'
import { GatsbyImage, getImage } from "gatsby-plugin-image"
import Map from '../images/map.png'
import { FaPhone, FaMapMarker, FaEnvelope } from 'react-icons/fa';
import Seo from '../components/global/Seo';

export default function Contact({data}) {

  const [name, setName] = useState()
  const [userEmail, setUserEmail] = useState()
  const [message, setMessage] = useState()
  const [notification, setNotification] = useState('')
  const nameRef = useRef()
  const emailRef = useRef()
  const messageRef = useRef()

  const {address, email, email2, fax, phone, staff} = data.wp.myOptionsPage.options

  const handleOnChange = (e) => {
    const target = e.target.id
    const value = e.target.value
    
    if(target === 'name'){
      setName(value)
    }
    if(target === 'email'){
      setUserEmail(value)
    }
    if(target === 'message'){
      setMessage(value)
    }
  }

  function encode(data) {
    return Object.keys(data)
      .map(
        (key) =>
          encodeURIComponent(key) + "=" + encodeURIComponent(data[key])
      )
      .join("&");
  }

  const resetFields = () => {
    nameRef.current.value = ''
    emailRef.current.value = ''
    messageRef.current.value = ''
  }

  const displayNotification = () => {
    setNotification('Thanks for getting in touch. We will reply to your message shortly.')
    setTimeout(() => {setNotification('')}, 2000)
  }

  const handleSubmit = (e) => {
    e.preventDefault();
    console.log(encode({
      "form-name": e.target.getAttribute("name"),
      "name": name,
      "email": userEmail,
      "message": message,
    })
    )
    fetch("/", {
      method: "POST",
      headers: { "Content-Type": "application/x-www-form-urlencoded" },
      body: encode({
        "form-name": e.target.getAttribute("name"),
        "name": name,
        "email": userEmail,
        "message": message,
      }),
    })
      .then(() => {
        resetFields()
        displayNotification()
      })
      .catch((error) => alert(error));
  };

  return (
    <div className='page-template-contact'>
    <Seo title="Contact" description="With origins dating from 1874, the distinguished Manchester Tennis &amp; Racquet Club (MTRC) has been situated on Blackfriars Road in Salford since 1880. This truly unique members' club is one of Manchester's greatest hidden treasures."  />
    <h1>Contact Us</h1>
    
    <ul className="staff">
      {
        staff.map(staffMember => {
        return (
          <li className="staff-member">
            <GatsbyImage image={getImage(staffMember.staffPicture.localFile.childImageSharp.gatsbyImageData)} />
            <div className="position">{staffMember.title}</div>
            <div className="name">{staffMember.name}</div>
            <div className="responsibilities">{staffMember.responsibilities}</div>
          </li>
        )
        })
      }

    </ul>


    <div id="map" style={{width:'100%', height:'500px'}}>
      <a target="_blank" href="https://www.google.com/maps/place/Manchester+Tennis+and+Racquet+Club/@53.4859753,-2.2546586,17z/data=!3m1!4b1!4m5!3m4!1s0x487bb190e3e1c341:0x1180107747bb22b3!8m2!3d53.4859721!4d-2.2524699?shorturl=1">
        <img src={Map} alt="Map" />
      </a>
    </div>
      
    <div className="contact-details">
      <div className="phone-fax">
          <span>
            <FaPhone />
          </span>
          <div className="phone">Tel: {phone}</div>
          <div className="phone">Fax: {fax}</div>
      </div>
      <div className="address-wrap">
          <span>
            <FaMapMarker />
          </span>
          <div className="address">{address}</div>
      </div>
      <div className="emails">
          <span>
            <FaEnvelope />
          </span>
          <div className="email">Email: <a href={`mailto:${email}`}>{email}</a></div>
          <div className="email">Email: <a href={`mailto:${email2}`}>{email2}</a></div>
      </div>  
    </div>
      
      
      <div className="form-wrap">
          <div className="form-intro">Get in touch to book a lesson, court or to speak to one of our team members.</div>
          <form className="form"  method="POST" data-netlify="true" name="contact" onSubmit={handleSubmit}>
            <input type="hidden" name="form-name" value="contact" />
            <div className="input-wrap">
              <label htmlFor="#name">Name <span className="gfield_required">*</span></label>
              <input id="name" name="name" ref={nameRef} onKeyUp={(e) => handleOnChange(e)}  type="text" required/>
            </div>
            <div className="input-wrap">
              <label htmlFor="#email">Email <span className="gfield_required">*</span></label>
              <input id="email" name="email" ref={emailRef} onKeyUp={(e) => handleOnChange(e)}  type="email" required/>
            </div>
            <div className="input-wrap">
              <label htmlFor="#message">Message</label>
              <textarea id="message" name="message" ref={messageRef} onKeyUp={(e) => handleOnChange(e)} ></textarea>
            </div>
            <div className="input-wrap">
              <input id="gform_submit_button_1" type="submit" value="Submit form" />
            </div>
            {
            notification !== '' ?
              <div className="input-wrap heading-7 red">
                {notification}
              </div>
            : ''
          }
          </form>
      </div>  
    </div>
  )
}


export const query = graphql`
{
  wp {
    myOptionsPage {
      options {
        address
        email
        email2
        fax
        phone
        staff {
          title
          responsibilities
          name
          staffPicture {
            localFile {
              childImageSharp {
                gatsbyImageData
              }
            }
          }
        }
      }
    }
  }
}
`